import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import IosCard from 'components/Software/iOS/InstarVision/iOSCard';
import VideoCard from 'components/Software/iOS/InstarVision/YoutubeVideoCard';
import YoutubeCards from 'components/Software/iOS/InstarVision/YoutubeVideoCards';
import NavButtons from 'components/Software/iOS/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer iPhone oder iPad App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_iOS.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_iOS.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='InstarVision Surveillance Center' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Um mit unserer iPhone oder iPad App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.' image='/images/Search/P_SearchThumb_InstarVision_iOS.png' twitter='/images/Search/P_SearchThumb_InstarVision_iOS.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/iOS/InstarVision/' locationFR='/fr/Software/iOS/InstarVision/' crumbLabel="InstarVision" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "ios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ios",
        "aria-label": "ios permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iOS`}</h2>
    <h3 {...{
      "id": "instarvision-for-iphone-and-ipad",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instarvision-for-iphone-and-ipad",
        "aria-label": "instarvision for iphone and ipad permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision for iPhone and iPad`}</h3>
    <IosCard mdxType="IosCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "verfügbare-youtube-videos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#verf%C3%BCgbare-youtube-videos",
        "aria-label": "verfügbare youtube videos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verfügbare Youtube Videos`}</h3>
    <VideoCard mdxType="VideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeCards mdxType="YoutubeCards" />
    {/* 
     <div class="flex-container">
        <div class="onecard">
         <a href="/Software/iOS/InstarVision/Video/Overview/">
            <div class="image">
                <img alt="Allgemeiner Überblick über die App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Allgemeiner Überblick über die App InstarVision</p>
            </div>
        </div>
     </div>
     
     <div class="flex-container">
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Installation/">
            <div class="image">
                <img alt="Installation der App InstarVision auf einem iPhone" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Installation der App InstarVision auf einem iPhone</p>
            </div>
        </div>
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Add_Cameras/">
            <div class="image">
                <img alt="Hinzufügen einer Kamera in der iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Hinzufügen einer Kamera in der iPhone App InstarVision</p>
            </div>
        </div>
     </div>
     
     <div class="flex-container">
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Multiview/">
            <div class="image">
                <img alt="Mehrere Kameras in der iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Mehrere Kameras in der iPhone App InstarVision</p>
            </div>
        </div>
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Settings/">
            <div class="image">
                <img alt="Erklärung der Einstellungen in iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Erklärung der Einstellungen in iPhone App InstarVision</p>
            </div>
        </div>
     </div>
     
     <div class="flex-container">
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Recordings/">
            <div class="image">
                <img alt="Aufnahmen und SD Karten Zugriff in iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Aufnahmen und SD Karten Zugriff in iPhone App InstarVision</p>
            </div>
        </div>
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Interface/">
            <div class="image">
                <img alt="Schaltflächen in der iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>Schaltflächen in der iPhone App InstarVision</p>
            </div>
        </div>
     </div>
     
     <div class="flex-container">
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Smartphone_App/">
            <div class="image">
                <img alt="iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>InstarVision IP-Kamera Smartphone App</p>
            </div>
        </div>
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/Export_Settings/">
            <div class="image">
                <img alt="iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>InstarVision Export Konfigurationen</p>
            </div>
        </div>
     </div>
     
     <div class="flex-container">
        <div class="twocard">
         <a href="/Software/iOS/InstarVision/Video/User_Management/">
            <div class="image">
                <img alt="iPhone App InstarVision" src="/images/Software/InstarVision_App_Installation.png" />
            </div>
          </a>
            <div class="action">
                <p>InstarVision Benutzerverwaltung</p>
            </div>
        </div>
        <div class="twocard invisible">
        </div>
     </div>
     */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      